<template>
  <div>
    <div class="page_BN"><img :src="Image" /></div>
    <div class="breadcrumb">
      <div class="page_member_info" v-if="User">
        <p>{{ User.Name }} 您好</p>
        <a href="javascript:void(0)" @click="$router.push('/my')">
          <span>我的帳號</span>
        </a>
        <a class="logOut" href="javascript:void(0)" @click="logout">
          <span>登出</span>
        </a>
      </div>
      <ul>
        <li>
          <a href="javascript:void(0)" @click="$router.push('/')">首頁</a>
        </li>
        <li v-for="(b, i) in Breadcrumbs" :key="i">
          <a href="javascript:void(0)" @click="goto(b)">{{ b.Title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import user from "../plugins/user";

export default {
  name: "PageBar",
  props: ["Image", "Breadcrumbs"],
  data() {
    return { User: null };
  },
  async mounted() {
    if (user.user) {
      this.User = await this.$api.getMe();
    }
  },
  methods: {
    async logout() {
      user.logout();
      this.User = null;
      this.$router.replace('/');
    },
    goto(b) {
      if (b.Url && b.Url.startsWith("/")) {
        this.$router.push(b.Url);
      }
    },
  },
};
</script>